import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'formik'
import { Button, HStack, Spacer } from '@northlight/ui'

import conf from '../../../mt-conf'

import TextInput from '../form/text-input'
import StayLoggedInCheckboxInput from '../form/stay-logged-in-checkbox-input'

const PasswordFactorForm = (props) => {
  const { isSubmitting } = props

  return (
    <>
      <Field
        name="username"
        type="email"
        placeholder="Email"
        icon="user"
        data-testid="login-form__input--email"
        component={ TextInput }
      />
      <Field
        name="password"
        type="password"
        placeholder="Password"
        icon="lock"
        className="loginput--with-link"
        data-testid="login-form__input--password"
        component={ TextInput }
        right={ <Link className="forgot" to="forgotpassword">Forgot?</Link> }
      />
      <HStack marginTop="4">
        <Field
          label="Stay logged in"
          name="stayLoggedIn"
          component={ StayLoggedInCheckboxInput }
        />
        <Spacer />
        { conf.quickLogin &&
          <Link to="quicklogin">Quick Login</Link>
        }
        <Button
          type="submit"
          variant="success"
          size="sm"
          isDisabled={ isSubmitting }
          data-testid="login-form__button--submit"
          backgroundColor="#052538"
          color="white"
          _hover={ { backgroundColor: '#E5856E' } }
        >
          Login
        </Button>
      </HStack>
    </>
  )
}

export default PasswordFactorForm
