import React, { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import {
  both,
  either,
  evolve,
  isEmpty,
  isNil,
  pathEq,
  trim,
} from 'ramda'
import { propEq } from 'yafu'
import cookie from '@mediatool/mt-frontend/lib/cookie'
import { Button, Divider, Flex, HStack } from '@northlight/ui'
import { login } from '../../requests/auth-requests'
import { createValidator } from '../../helpers/form-helpers'

import { useShake } from '../../hooks'
import FormLoader from './form-loader'
import StatusMessage from '../form/status-message'
import FormHeader from './form-header'
import PasswordFactorForm from './password-factor-form'
import OTPFactorForm from './otp-factor-form'

const isEmptyOrNil = either(isNil, isEmpty)

const isOTPRequiredError = both(
  propEq('name', 'DataIntegrityCheckFailed'),
  pathEq([ 'meta', 'type' ], '2FAEnabled')
)

const validation = [
  { prop: 'username', predicate: isEmptyOrNil, error: 'Please enter your email.' },
  { prop: 'password', predicate: isEmptyOrNil, error: 'Please enter your password.' },
]

const LoginForm = () => {
  const history = useHistory()
  const location = useLocation()
  const ref = useRef()
  const { shake } = useShake(ref)

  const [ factor, setFactor ] = useState('Password')

  const initialValues = {
    stayLoggedIn: cookie.get('defaultStayLoggedIn') === 'true',
  }
  const initialStatus = {}

  const handleSubmit = (values, actions) => {
    const onError = (e) => {
      actions.setSubmitting(false)

      if (isOTPRequiredError(e)) {
        setFactor('OTP')
        actions.setStatus(initialStatus)
        return
      }

      const status = e.name === 'ValidationError'
        ? { factor: 'Password', message: { type: 'error', message: 'The supplied email or password is incorrect.' } }
        : { factor: 'Password', message: { type: 'error', message: e.message } }

      shake()
      actions.setStatus(status)
    }

    const onSuccess = () => {
      window.location = `/${window.location.search}`
    }

    cookie.set('defaultStayLoggedIn', values.stayLoggedIn, 60 * 60 * 24 * 365)

    login(evolve({ username: trim }, values))
      .fork(onError, onSuccess)
  }

  useEffect(() => {
    localStorage.setItem('loginMethod', 'credentials')
  }, [])

  const onSSOClicked = () => {
    history.push({
      pathname: '/sso',
      search: location.search,
    })
  }
  return (
    <Formik
      onSubmit={ handleSubmit }
      initialValues={ initialValues }
      initialStatus={ initialStatus }
      validate={ createValidator(validation) }
    >
      { (form) => (
        <div className="form animated" ref={ ref }>
          <FormHeader factor={ factor } />
          <Form className="form">
            { form.isSubmitting &&
              <FormLoader />
            }

            { form.status.message &&
              <StatusMessage { ...form } />
            }

            { factor === 'Password' &&
              <PasswordFactorForm { ...form } />
            }
            { factor === 'OTP' &&
              <OTPFactorForm { ...form } />
            }
          </Form>

          <HStack justifyContent="space-evenly" marginTop="4" gap="2">
            <Divider />
            <Flex>
              <Button
                size="sm"
                variant="link"
                color="#E5856E"
                textDecoration="none"
                onClick={ onSSOClicked }
                data-testid="login-with-sso__button"
              >
                or continue with Single Sign-On
              </Button>
            </Flex>
            <Divider />
          </HStack>

          <div className="align-center m-t-m t-s">
            <span>Don't have a Camphouse account?</span><br />
            <a href="https://camphouse.io/tour">Request a demo of Camphouse</a>
          </div>
        </div>
      ) }
    </Formik>
  )
}

export default LoginForm
