import React, { useEffect } from 'react'
import qs from 'qs'
import { Icon, Toast, useToast } from '@northlight/ui'
import { AlertCircleDuo } from '@northlight/icons'
import { useLocation } from 'react-router-dom'
import { has, isNil } from 'ramda'

const errorKeyMap = {
  IDPNonExistent: 'The selected IDP does not exist.',
  IDPDisabled: 'The selected IDP is disabled.',
  AccountMissing: 'The specified user is not registered in Camphouse.',
  UserIsMissing: 'The specified user does not have an activated account in Camphouse.',
  SSODisabled: 'SSO for your organization is disabled.',
  SSOUserIsAlreadyLinked: 'The specified account is already linked to another IDP.',
  UserDoesNotBelongToOrg: 'The user does not belong to this organization.',
}

export function usePathError () {
  const location = useLocation()
  const toast = useToast()

  function getErrorKey () {
    const { search } = location
    const { error } = qs.parse(search, { ignoreQueryPrefix: true })

    return !isNil(error) && has(error, errorKeyMap)
      ? error
      : null
  }

  function displayError (errorKey) {
    toast({
      position: 'top',
      render: ({ onClose }) => (
        <Toast
          icon={ <Icon as={ AlertCircleDuo } /> }
          variant="error"
          description={ errorKeyMap[errorKey] }
          onClose={ onClose }
        />
      ),
    })
  }

  useEffect(
    () => {
      const errorKey = getErrorKey()

      if (!isNil(errorKey)) {
        displayError(errorKey)
      }
    },
    []
  )
}
