import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { either, isEmpty, isNil, trim } from 'ramda'

import { Button, HStack, Spacer } from '@northlight/ui'
import { requestPasswordReset } from '../../requests/auth-requests'
import { createValidator } from '../../helpers/form-helpers'

import { useShake } from '../../hooks'
import TextInput from '../form/text-input'
import StatusMessage from '../form/status-message'

const isEmptyOrNil = either(isNil, isEmpty)

const validation = [
  { prop: 'email', predicate: isEmptyOrNil, error: 'Please enter the email belonging to your Camphouse account in order to reset your password.' },
]

const ForgotPasswordForm = () => {
  const ref = useRef()
  const { shake } = useShake(ref)

  const initialValues = { email: '' }
  const initialStatus = {}

  const handleSubmit = (values, actions) => {
    const onError = (e) => {
      actions.setSubmitting(false)
      shake()
      const status = {
        message: { type: 'error', message: e.message },
      }
      actions.setStatus(status)
    }
    const onSuccess = () => {
      actions.setSubmitting(false)
      actions.resetForm()
      const status = {
        message: { type: 'success', message: `A password reset code has been sent to ${values.email}` },
      }
      actions.setStatus(status)
    }

    requestPasswordReset(trim(values.email))
      .fork(onError, onSuccess)
  }

  return (
    <div className="form animated" ref={ ref }>
      <div className="form__header">
        <h1 className="h1">Forgot your password?</h1>
        <div className="form__link">
          Back to <Link className="back" to="login">login</Link>
        </div>
        <p>
          Please enter the e-mail that you used when you signed up to
          Camphouse and we will send you a reset link to that e-mail.
        </p>
      </div>
      <Formik
        onSubmit={ handleSubmit }
        initialValues={ initialValues }
        initialStatus={ initialStatus }
        validate={ createValidator(validation) }
      >
        { (form) => (
          <Form>
            { form.status.message &&
              <StatusMessage { ...form } />
            }

            <Field
              name="email"
              placeholder="Email"
              type="email"
              icon="user"
              component={ TextInput }
            />

            <HStack marginTop="4">
              <Spacer />

              <Button
                type="submit"
                variant="danger"
                size="sm"
                isDisabled={ form.isSubmitting }
              >
                Reset password
              </Button>
            </HStack>
          </Form>
        ) }
      </Formik>
    </div>
  )
}

export default ForgotPasswordForm
