import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import transitioner from '@mediatool/mt-frontend/web/transitioner'
import { usePathError } from '../hooks'

import conf from '../../mt-conf'

import LoginForm from '../components/login-form/login-form'
import { LoginSSOForm } from '../components/login-form/login-sso-form'
import { ActivateSso } from '../components/login-form/activate-sso'
import ForgotPasswordForm from '../components/forgot-password-form/forgot-password-form'
import ResetPasswordForm from '../components/reset-password-form/reset-password-form'
import QuickLogin from '../components/quick-login/quick-login'

const getLoginPath = () => {
  const loginMethod = localStorage.getItem('loginMethod') ?? 'credentials'
  return loginMethod === 'sso' ? '/sso' : '/login'
}
const WantedPathRedirect = () => {
  const history = useHistory()
  useEffect(() => {
    const wantedURL = transitioner.getPathWithQuery().substring(1)
    history.replace(`${getLoginPath()}?wantedURL=${decodeURIComponent(wantedURL)}`)
  }, [])

  return null
}
const LoginRedirect = () => {
  const history = useHistory()
  return <Redirect to={ { ...history.location, pathname: getLoginPath() } } />
}
const LoginContainer = () => {
  usePathError()

  return (
    <div className="page">
      <div className="login-image login-image--top" />
      <div className="login-image login-image--bottom-left" />
      <div className="login-image login-image--bottom-right" />
      <div className="login">
        <div className="page__right-col">
          <div className="logo" />
          <Switch>
            <Route exact={ true } path="/" component={ LoginRedirect } />
            <Route path="/forgotpassword" component={ ForgotPasswordForm } />
            <Route path="/resetpassword/:resetCode" component={ ResetPasswordForm } />
            <Route path="/login" component={ LoginForm } />
            <Route path="/sso" component={ LoginSSOForm } />
            { conf.devFeatures && <Route path="/activateSso" component={ ActivateSso } /> }
            { conf.quickLogin && <Route path="/quicklogin" component={ QuickLogin } /> }
            <Route component={ WantedPathRedirect } />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
