import React from 'react'
import { propOr } from 'ramda'
import { Checkbox, HStack, Label } from '@northlight/ui'

const StayLoggedInCheckboxInput = (props) => {
  const {
    form,
    field,
    label,
  } = props
  const { errors, touched } = form
  const { name } = field

  const error = propOr('', name, errors)
  const showError = errors[name] && touched[name]

  return (
    <>
      { showError &&
        <span className="pull-right red-text">{ error }</span>
      }
      <HStack gap="2">
        <Checkbox
          variant="rounded"
          { ...field }
        />
        <Label htmlFor={ name } sx={ { fontWeight: 'normal' } } size="sm">
          { label }
        </Label>
      </HStack>
    </>
  )
}

export default StayLoggedInCheckboxInput
